const descendingComparator = (a, b, orderBy) => {
  if (orderBy === "fecha") {
    let texa = a[orderBy];
    let texb = b[orderBy];
    const fechaA = new Date(
      Date.UTC(
        parseInt(texa.substring(6, 10)),
        parseInt(texa.substring(3, 5)) - 1,
        parseInt(texa.substring(0, 2)),
        0,
        0,
        0
      )
    );
    const fechaB = new Date(
      Date.UTC(
        parseInt(texb.substring(6, 10)),
        parseInt(texb.substring(3, 5)) - 1,
        parseInt(texb.substring(0, 2)),
        0,
        0,
        0
      )
    );
    if (fechaB.getTime() < fechaA.getTime()) {
      return -1;
    }
    if (fechaB.getTime() > fechaA.getTime()) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) {
      return order;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

function nroFac(nro) {
  let numero;
  let largo = nro.length;
  if (largo === 1) {
    numero = "000000" + nro;
  }
  if (largo === 2) {
    numero = "00000" + nro;
  }
  if (largo === 3) {
    numero = "0000" + nro;
  }
  if (largo === 4) {
    numero = "000" + nro;
  }
  if (largo === 5) {
    numero = "00" + nro;
  }
  if (largo === 6) {
    numero = "0" + nro;
  }
  if (largo === 7) {
    numero = nro;
  }
  return numero;
}

function numberFormatDecimal(numero, t) {
  var resultado = "";
  var numeroNuevo = "";
  var nroStr = numero + "";

  nroStr = nroStr.replace(".", ",");
  numeroNuevo = nroStr.replace(".", ",");
  if (nroStr.indexOf(",") > 0) {
    numeroNuevo = numeroNuevo.substring(0, nroStr.indexOf(","));
  }
  for (var j, i = numeroNuevo.length - 1, j = 0; i >= 0; i--, j++) {
    resultado =
      numeroNuevo.charAt(i) + (j > 0 && j % 3 === 0 ? "." : "") + resultado;
  }

  if (nroStr.indexOf(",") > 0 && nroStr.indexOf(",") !== nroStr.length - 1) {
    resultado += nroStr.substring(nroStr.indexOf(","));
  }
  return resultado;
}

export {
  descendingComparator,
  getComparator,
  stableSort,
  nroFac,
  numberFormatDecimal,
};
